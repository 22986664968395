import React from "react"
import { Head } from "trv-ebus-tcom-reactcomponents"
import Shareaholic from "./shareaholic"

const DefaultHead = ({
  htmlAttributes,
  canonicalUrl,
  pageInformation,
  tagman
}) => (
  <>
    <Head htmlAttributes={htmlAttributes}>
      <meta http-equiv="content-type" content="text/html; charset=utf-8" />
      <link rel="canonical" href={canonicalUrl} />
      <script src="/js/jquery.min.js"></script>
    </Head>
    <Shareaholic />
    {pageInformation}
    {tagman}
  </>
)

export default DefaultHead
